.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  transition: color 0.25s linear, border-color 0.25s linear,
    background-color 0.25s linear;

  &--orange {
    padding: 18px 40px;
    border: 2px solid transparent;
    background-color: var(--orange-2);
    font-weight: var(--font-weight-medium);
    font-size: 20px;
    line-height: 25px;
    color: var(--white);

    &:focus {
      background-color: var(--orange-4);
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--orange-4);
      }
    }
  }

  &--blue {
    padding: 8px 20px;
    border: 2px solid transparent;
    background-color: var(--blue-3);
    font-weight: var(--font-weight-medium);
    font-size: 14px;
    line-height: 25px;
    color: var(--white);

    &:focus {
      background-color: var(--blue-3);
    }

    &.disabled {
      opacity: 0.5;

      @media (hover: hover) {
        &:hover {
          background-color: var(--blue-3);
          cursor: unset;
        }
      }
    }

    &.hide {
      opacity: 0;
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--blue-1);
      }
    }

    @media screen and (min-width: 1920px) {
      padding: 18px 40px;
      font-size: 20px;
    }
  }

  &--orange-invert {
    padding: 15px 40px;
    // for ILTB
    // border: 2px solid var(--orange-2);
    // color: var(--orange-2);
    border: 2px solid var(--blue-3);
    color: var(--blue-3);
    font-weight: var(--font-weight-medium);
    font-size: 20px;
    line-height: 25px;
    background-color: transparent;

    &:focus {
      // for ILTB
      // border-color: var(--orange-4);
      // color: var(--orange-4);
      border-color: var(--blue-1);
      color: var(--blue-1);
    }

    @media (hover: hover) {
      &:hover {
        // for ILTB
        // border-color: var(--orange-4);
        // color: var(--orange-4);
        border-color: var(--blue-1);
        color: var(--blue-1);
      }
    }
  }

  &--white {
    padding: 20px 40px;
    border: 2px solid transparent;
    background-color: var(--white);
    font-weight: var(--font-weight-medium);
    font-size: 20px;
    line-height: 25px;
    color: var(--mint);

    &:focus {
      background-color: var(--light-gray);
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--light-gray);
      }
    }
  }

  &--white-invert {
    padding: 15px 40px;
    border: 2px solid var(--white);
    font-weight: var(--font-weight-medium);
    font-size: 20px;
    line-height: 25px;
    background-color: transparent;
    color: var(--white);

    &:focus {
      border-color: var(--light-gray);
      color: var(--light-gray);
    }

    @media (hover: hover) {
      &:hover {
        border-color: var(--light-gray);
        color: var(--light-gray);
      }
    }
  }
}
