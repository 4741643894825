@import 'src/breakpoints';

.page-dashboard-participant {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: none !important;
  padding: 56px 32px !important;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
    background-image: url(../../../../assets/vector/gradients/bg-image.svg);
    opacity: 0.7;
    z-index: -1;
  }

  .page-title {
    font-weight: 700;
    margin: 0 0 20px;
    color: var(--black);
    font-size: 56px;
    line-height: 105%;
    letter-spacing: 0.01em;
  }

  .page-subtitle {
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 150%;
    color: var(--black);
  }

  .page-links {
    display: flex;
    margin: 46px 0 0;
    z-index: 1;
    width: 100%;

    .item_link {
      max-width: 700px;
      width: 100%;
      min-height: 400px;
      margin-right: 24px;
      .item {
        background: #fff;
        position: relative;
        // for ILTB
        // border: 1px solid #EBCDB9;
        box-sizing: border-box;
        box-shadow: 0px 6px 12px rgba(199, 206, 216, 0.5);
        border-radius: 24px;
        padding: 32px 27px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .item_row {
          display: flex;
          align-items: center;
          .img_wrapper {
            width: 64px;
            height: 64px;
            border-radius: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 28px;
            -webkit-transition: background 600ms linear;
            -moz-transition: background 600ms linear;
            -o-transition: background 600ms linear;
            -ms-transition: background 600ms linear;
            transition: background 600ms linear;
            img {
              width: 24px;
              height: 24px;
            }
          }
          .link-text {
            font-weight: var(--font-weight-medium);
            font-size: 24px;
            line-height: 125%;
          }
        }
        .item_img {
          max-height: 300px;
          max-width: 70%;
          margin: 0 0 0 auto;
          margin-bottom: -50px;
        }
      }

      &:nth-child(1) {
        .item {
          .img_wrapper {
            background: rgba(236, 78, 124, 0.1);
          }
        }
      }

      &:nth-child(2) {
        .item {
          .img_wrapper {
            background: rgba(91, 203, 201, 0.1);
          }
        }
      }
      transition: 0.5s all ease;
      &:hover {
        margin-top: -15px;
        margin-bottom: 15px;
        transition: 0.5s all ease;
        &:nth-child(1) {
          .item {
            .img_wrapper {
              background: rgb(236, 78, 124);
              transition: 0.5s all ease;
              img {
                filter: brightness(0) invert(1);
              }
            }
          }
        }

        &:nth-child(2) {
          .item {
            .img_wrapper {
              background: rgb(91, 203, 201);

              img {
                filter: brightness(0) invert(1);
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: $tablet-4) {
    .page-title {
      font-size: 32px;
    }

    .page-links {
      margin: 36px 0 0;

      .item_link {
        .item {
          .item_row {
            .img_wrapper {
              width: 64px;
              height: 64px;
              margin-right: 12px;
            }
            .link-text {
              font-size: 22px;
            }
          }
          .item_img {
            max-width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: $tablet) {
    .page-title {
      text-align: center;
      margin: 0 0 10px;
    }

    .page-subtitle {
      font-weight: var(--font-weight-medium);
      font-size: 15px;
      line-height: 150%;
      text-align: center;
    }

    .page-links {
      flex-direction: column;
      margin: 24px 0 0;
      width: 100%;
      .item_link {
        margin-bottom: 20px;
        min-height: 200px;
        .item {
          .item_img {
            max-width: 80%;
            margin-bottom: 0;
            max-height: 150px;
          }
        }
      }
    }
  }
}
